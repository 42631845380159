// extracted by mini-css-extract-plugin
export var aboutDesc = "cartootengoku-module--aboutDesc--qH7h6";
export var aboutName = "cartootengoku-module--aboutName--M32Bk";
export var aboutSocials = "cartootengoku-module--aboutSocials--SBVJR";
export var article = "cartootengoku-module--article--FTD7Z";
export var button = "cartootengoku-module--button--k7HOM";
export var buttons = "cartootengoku-module--buttons--yRiCi";
export var flexCenter = "cartootengoku-module--flexCenter--F5Ps2";
export var panel = "cartootengoku-module--panel--ywSDv";
export var panelTitle = "cartootengoku-module--panelTitle--o-dby";
export var panels = "cartootengoku-module--panels--eCFHK";