import React, { useState } from 'react';
import Seo from "../components/seo"
import Layout from "../components/layout"
import Article from "../components/article"
import getLanguage from "../utilt/getLangueage"
import useWindowSize from "../utilt/useWindowSize"

//CSS
import { article } from "./cartootengoku.module.css"
import { flexCenter } from "./cartootengoku.module.css"
import { aboutSocials } from "./cartootengoku.module.css"
import { panels } from "./cartootengoku.module.css"
import { panel } from "./cartootengoku.module.css"
import { panelTitle } from "./cartootengoku.module.css"
import { buttons } from "./cartootengoku.module.css"
import { button } from "./cartootengoku.module.css"
import { aboutDesc } from "./cartootengoku.module.css"
import { aboutName } from "./cartootengoku.module.css"


import portrait from "../images/about/katotenPortrait.jpg"
//import twitterLogo from "../images/scLogo_twitter2.png"
import XLogo from "../images/scLogo_X2.png"
import instagramLogo from "../images/scLogo_instagram2.png"
import fxhashLogo from "../images/scLogo_fxhash.png"
import neortLogo from "../images/scLogo_neort2.png"
import adobeStockLogo from "../images/scLogo_adobeStock.png"
import henohenoSamurai from "../images/cartoonTengoku/henohenoSamurai.png"
import abstractCalligraphy from "../images/cartoonTengoku/abstractCalligraphy.png"
import infiniteAnimation from "../images/cartoonTengoku/infiniteAnimation.png"
import puzzledPicture from "../images/cartoonTengoku/puzzledPicture.png"
//import neverEndingDrawingRabbit from "../images/cartoonTengoku/neverEndingDrawingRabbit.png"
import generativeDinosaur from "../images/cartoonTengoku/generativeDinosaur.png"
//import generativeDinosaurPattern from "../images/cartoonTengoku/generativeDinosaurPattern.png"
//import generativeDinosaurPattern2 from "../images/cartoonTengoku/generativeDinosaurPattern2.png"



/**
 * ポートレイト画像
 */
const Portrait = props => {
    return (
        <img
            src={props.pic}
            alt={props.alt}
            style={{
                maxWidth: `${256 - Math.max(320 - props.winSize.width, 0)}px`,
                height: `256px`,
                borderRadius: "50%",
                border: "2px solid #111111",
            }}
        />
    )
}

/**
 * ソーシャルロゴ
 */
const ScLogo = props => {
    return (
        <a
            href={props.href}
        >
            <img
                src={props.logo}
                alt={props.alt}
                title={props.alt}
                style={props.hide ? {
                    width: 0,
                    height: 0,
                    margin: 0,
                } : {
                    maxWidth: `48px`,
                    margin: `6px`
                }}
            />
        </a>
    )
}

/**
 * パネル・コンポーネント
 */
const Panel = props => {
    return (
        <div
            className={panel}
        >
            <img
                src={props.pic}
                alt={props.alt}
                style={{
                    maxWidth: `280px`,
                }}
            />
            <h5
                className={panelTitle}
            >
                {props.title}
            </h5>
            <div className={buttons}>
                <a
                    className={button}
                    href={props.open}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    open
                </a>
                <a
                    className={button}
                    href={props.site}
                >
                    {props.butName}
                </a>
            </div>
        </div >
    )
}


/**
 * ページ
 */
const CartoonTengoku = (props) => {
    const [lang, setLang] = useState(getLanguage());
    function setLanguage(enOrJa) {
        setLang(enOrJa);
    }
    const winSize = useWindowSize() || props.size || { width: 640, height: 948 }


    return (
        <Layout
            checked=""
            winSize={winSize}
            hideHeader="true"
        //hideNavs="true"
        >
            <Seo
                title="katoten's page"
                description={lang !== "ja" ?
                    "katoten" :
                    "カトテン"
                }
                lang={lang}
                image="https://www.drawinghell.com/ogp_images/seo_katoten.jpg"
                pathname="/cartootengoku"
            />
            <Article
                isLangBar="false"
                winSize={winSize}
                setLang={setLanguage}
                lang={props.lang}
            >
                <div
                    className={article}
                >
                    <Portrait
                        winSize={winSize}
                        pic={portrait}
                        alt="katoten's portrait"
                        margin="0"
                    />
                    <div
                        className={aboutDesc}
                    >
                        <h4 className={aboutName}>
                            {lang !== "ja" ? "katoten" : "カトテン"}
                        </h4>
                        <p
                            style={{
                                marginTop: "0.2rem"
                            }}>
                            Illustration/Cartoon/Generative Art
                        </p>
                    </div>

                    <div
                        className={flexCenter}
                        style={{
                            margin: "0.25rem 0 0"
                        }}
                    >
                        <div
                            className={aboutSocials}
                        >
                            <ScLogo
                                logo={XLogo}
                                href="https://twitter.com/cartoontengoku"
                                alt="X"
                                winSize={winSize}
                            />
                            <ScLogo
                                logo={instagramLogo}
                                href="https://www.instagram.com/cartoontengoku/"
                                alt="instagram"
                                winSize={winSize}
                            />
                            <ScLogo
                                logo={fxhashLogo}
                                href="https://www.fxhash.xyz/u/katoten"
                                alt="fxhash"
                                winSize={winSize}
                            />
                            <ScLogo
                                logo={neortLogo}
                                href="https://neort.io/@Hu2sBEJ9QrXHcZ1FsX6Lny8d5YJ2"
                                alt="NEORT"
                                winSize={winSize}
                            />
                            <ScLogo
                                logo={adobeStockLogo}
                                href="https://stock.adobe.com/contributor/209635142/%E3%82%AB%E3%83%88%E3%83%86%E3%83%B3"
                                alt="Adobe Stock"
                                winSize={winSize}
                            />
                        </div>
                    </div>
                    <div>
                        <hr />
                    </div>
                    <h3
                        className={flexCenter}
                        style={{
                            marginTop: "0.75rem",
                            marginBottom: "1.25rem"
                        }}
                    >
                        works
                    </h3>

                    <div
                        className={panels}
                    >
                        <Panel
                            pic={generativeDinosaur}
                            alt="Generative Dinosaur"
                            title="Generative Dinosaur"
                            open="https://www.drawinghell.com/katoten/generativeDinosaur/"
                            butName="fxhash"
                            site="https://www.fxhash.xyz/generative/25462"
                        ></Panel>
                        <Panel
                            pic={puzzledPicture}
                            alt="Puzzled Picture"
                            title="Puzzled Picture"
                            open="https://www.drawinghell.com/katoten/puzzledPicture/"
                            butName="fxhash"
                            site="https://www.fxhash.xyz/generative/16454"
                        ></Panel>
                        <Panel
                            pic={infiniteAnimation}
                            alt="Infinite Animation"
                            title="Infinite Animation"
                            open="https://www.drawinghell.com/katoten/infiniteAnimation/"
                            butName="fxhash"
                            site="https://www.fxhash.xyz/generative/15745"
                        ></Panel>
                        <Panel
                            pic={abstractCalligraphy}
                            alt="Abstract Calligraphy"
                            title="Abstract Calligraphy"
                            open="https://www.drawinghell.com/katoten/abstractCalligraphy/"
                            butName="fxhash"
                            site="https://www.fxhash.xyz/generative/15384"
                        ></Panel>
                        <Panel
                            pic={henohenoSamurai}
                            alt="Henoheno Samurai"
                            title="Henoheno Samurai"
                            open="https://www.drawinghell.com/katoten/henohenoSamurai/"
                            butName="fxhash"
                            site="https://www.fxhash.xyz/generative/14176"
                        ></Panel>

                    </div>

                </div>

            </Article>
        </Layout>
    )
}
export default CartoonTengoku

/*
                                                <Panel
                            pic={generativeDinosaurPattern2}
                            alt="Generative Dinosaur(pattern) 2"
                            title="Generative Dinosaur(pattern) 2"
                            open="https://www.drawinghell.com/katoten/generativeDinosaurPattern2/"
                            butName = "NEORT"
                            site="https://neort.io/art/ch712tkn70rkl4ca3ur0"
                        ></Panel>
                        <Panel
                            pic={generativeDinosaurPattern}
                            alt="Generative Dinosaur(pattern)"
                            title="Generative Dinosaur(pattern)"
                            open="https://www.drawinghell.com/katoten/generativeDinosaurPattern/"
                            butName = "NEORT"
                            site="https://neort.io/art/cgtho7sn70rhlpf0m2c0"
                        ></Panel>
                        <Panel
                            pic={neverEndingDrawingRabbit}
                            alt="Never Ending Drawing Rabbit"
                            title="Never Ending Drawing Rabbit"
                            open="https://www.drawinghell.com/katoten/neverEndingDrawingRabbit/"
                            butName = "NEORT"
                            site="https://neort.io/art/ceam3ocn70rvflp1e1og"
                        ></Panel>

                        
                    <p
                        className={flexCenter}
                        style={{
                            marginTop:"0.5rem",
                            marginBottom:"1.5rem"
                        }}
                    >
                        {lang !== "ja"
                            ? "For a description of works, see the fxhash site page."
                            : "操作方法など各作品の説明はfxhashの頁をご覧ください"}
                    </p>
*/